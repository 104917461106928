<template>
  <ion-page>
    <ion-content class="radio-content">
      <div class="radio-refresh-wrapper" @click="getStreamsInfo">
        <div><MdRefreshIcon w="30px" h="30px" :animate="refreshingStreams ? 'rotate' : ''" /></div>
        <div>Alle Streams aktualisieren</div>
      </div>
      <ion-card v-for="stream in streams" 
        :key="stream.id" @click="setStreamUrl(stream.streamUrl, stream.currentSong, stream.title)">
        <ion-item>
          <div class="radio-card-play-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
              <path fill="#F4E518" fill-rule="evenodd" d="M18 12L0 24V0" class="play-pause-icon" id="playPause"></path>
            </svg>
          </div>
          <ion-label class="radio-title"><span class="yellow-space">{{ stream.title }}</span></ion-label>
        </ion-item>
        <ion-card-content>
          <ion-label>{{ stream.currentSong }}</ion-label>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonItem, IonCardContent, IonLabel } from '@ionic/vue'
import MdRefreshIcon from 'vue-ionicons/dist/md-refresh.vue'
export default ({
  name: 'Radio',
  data () {
    return {
      streams: [
        {id: 1, title: 'Balladen', currentSong: '', streamUrl: 'https://stream.radio-fsn.de/balladen' },
        {id: 2, title: 'Black Metal', currentSong: '', streamUrl: 'https://stream.radio-fsn.de/blackmetal' },
        {id: 3, title: 'Hardcore', currentSong: '', streamUrl: 'https://stream.radio-fsn.de/hardcore' },
        {id: 4, title: 'Rock', currentSong: '', streamUrl: 'https://stream.radio-fsn.de/rock' },
      ],
      refreshingStreams: false
    }
  },
  mounted() {
    this.getStreamsInfo()
  },
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonItem,
    IonCardContent,
    MdRefreshIcon,
    IonLabel
  },
  computed: {
    streamUrl: {
      get() {
        return this.$store.state.streamUrl
      },
      set() {

      }
    }
  },
  methods: {
    setStreamUrl(streamUrl, currentSong, streamName) {
      this.$store.commit('streamUrlChange', streamUrl)
      this.$store.commit('currentSongChange', currentSong)
      this.$store.commit('currentStreamNameChange', streamName)
    },
    getStreamsInfo() {
      var streams = this.streams
      this.refreshingStreams = true
      this.axios.get(this.$store.state.xhrBaseUrl + '/getRadioInfos.php').then((response) => {
        var count = 0;
        response.data.forEach(function(entry) {
          if (entry.streamUrl == streams[count].streamUrl) {
            streams[count].currentSong = entry.title
          }
          count++
        })
        this.streams = streams
        this.refreshingStreams = false
      })
    }
  }
});
</script>

<style>

</style>