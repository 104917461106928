<script>
export default {
  name: 'RadioStreamInfo',
  data () {
    return {
    }
  },
  mounted() {
    this.getActiveStreamInfo()
    setInterval(() => { this.getActiveStreamInfo() }, 15000);
  },
  methods: {
    getActiveStreamInfo() {
      this.axios.post(this.$store.state.xhrBaseUrl + '/getRadioInfos.php', null, { params: {
        getActiveStreamInfo: true,
        stream: this.$store.state.streamUrl
      }}).then((response) => {
        this.$store.commit('currentSongChange', response.data[0].title)
      })
    }
  }
}
</script>

<style>

</style>