<template>
  <ion-page>
    <ion-content class="home-content">
      <div id="container">
        <div class="container-bg-wrapper">
          <div class="home-content-inner">
            <div v-html="styles"></div>
            <div v-html="headline"></div>
            <div v-html="content"></div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
  },
  data () {
    return {
      headline: "",
      content: "",
      styles: ""
    }
  },
  mounted() {
    this.getStartpageContent();
  },
  methods: {
    getStartpageContent() {
      this.axios.get(this.$store.state.xhrBaseUrl + '/startpage-content.json').then((response) => {
        this.styles = response.data.styles;
        this.headline = response.data.headline;
        this.content = response.data.content;
      })
    }
  }
});
</script>

<style scoped>
.home-content-inner {
  text-align: center;
  color: #F4E518;
  padding: 30px 10px 30px 10px;
}
</style>