<template>
  <ion-page>
    <ion-content>
      <ion-list>
        <ion-card v-for="video in videosShowing" :key="video.id">
          <ion-item><a :href="video.url" class="video-title-link yellow-space" target="_blank">{{ video.title }} <MdOpenIcon class="open-icon" /></a></ion-item>
          <ion-item>
            <iframe
              :src="video.embed_link"
              style="min-height: 400px; width: 100%"
              frameborder="0"
              sandbox="allow-same-origin allow-scripts"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </ion-item>

          <ion-card-content>{{ video.summary }}</ion-card-content>
        </ion-card>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="showMoreVideos($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import MdOpenIcon from 'vue-ionicons/dist/md-open.vue'
import {
  IonContent,
  IonPage,
  ionCard,
  ionItem,
  IonCardContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from "@ionic/vue";

export default {
  name: "Videos",
  data() {
    return {
      videos: [],
      videosShowing: [],
      countVideosLoaded: 0,
      countLoadMore: 5
    };
  },
  components: {
    IonContent,
    IonPage,
    ionCard,
    ionItem,
    IonCardContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    MdOpenIcon
  },
  mounted() {
    this.getVideosXHR();
  },
  methods: {
    getVideosXHR() {
      var videos = [];
      this.axios
        .get("https://gorf.tube/feeds/videos.json?videoChannelId=342")
        .then((response) => {
          response.data.items.forEach(function (entry) {
            var tempArr = [];

            tempArr["title"] = entry.title;
            tempArr["summary"] = entry.summary;
            tempArr["embed_link"] = entry.url.replace("watch", "embed");
            tempArr["url"] = entry.url;
            videos.push(tempArr);
          });
          this.videos = videos;
          this.pushVideos()
        });
    },
    pushVideos() {
      this.countVideoLoad = this.countVideosLoaded + this.countLoadMore
      console.log(this.countVideoLoad);
      for (let i = 0; i < this.countVideoLoad; i++) {
        this.videosShowing.push(this.videos[i]);
      }
    },
    showMoreVideos(ev) {
      console.log(this.videos, ev);
      setTimeout(() => {
        this.pushVideos();
        console.log('Loaded data');
        ev.target.complete();

        // App logic to determine if all data is loaded
        // and disable the infinite scroll
        if (this.videosShowing.length == this.videos.length) {
          ev.target.disabled = true;
        }
      }, 500);
    }
  },
};
</script>

<style>
</style>