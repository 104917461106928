<template>
  <ion-app>
    <ion-page>
      <ion-alert-controller></ion-alert-controller>
      <ion-menu side="start" menu-id="first" content-id="main-content">
        <ion-header>
          <ion-toolbar color="primary">
            <ion-title class="yellow-space">Menü</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list>
            <ion-menu-toggle><ion-item router-link="/">STARTSEITE</ion-item></ion-menu-toggle>
            <ion-menu-toggle><ion-item router-link="/radio">RADIO FSN</ion-item></ion-menu-toggle>
            <ion-menu-toggle><ion-item router-link="/videos">FSN VIDEOS</ion-item></ion-menu-toggle>
          </ion-list>
        </ion-content>
        <ion-footer class="menu-footer">
          <div class="menu-footer-social-media">
              <div class="telegram-icon">
                <a href="https://t.me/fsnrev" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"/></svg></a>
              </div>
              <div><a href="https://t.me/fsnrev" target="_blank">Telegram</a></div>
          </div>
          <div class="menu-footer-social-media">
              <div class="youtube-icon">
                <a href="https://www.youtube.com/channel/UC8x4U3sYc_Q7ECYOnBpWynQ" target="_blank"><svg viewBox="0 0 512 512" width="3em" height="3em" class="ion__svg"><path d="M508.6 148.8c0-45-33.1-81.2-74-81.2C379.2 65 322.7 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.6-.1 220.2 0 255.8c-.1 35.6 1 71.2 3.4 106.9 0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8 60.8.2 120.3-1 178.6-3.8 40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107 .2-35.6-.9-71.2-3.3-106.9zM207 353.9V157.4l145 98.2-145 98.3z"></path></svg></a>
              </div>
              <div><a href="https://www.youtube.com/channel/UC8x4U3sYc_Q7ECYOnBpWynQ" target="_blank">YouTube</a></div>
          </div>
        </ion-footer>
      </ion-menu>
      <ion-header>
        <ion-toolbar>
          <ion-menu-button slot="end" color="secondary"></ion-menu-button>
          <ion-title class="yellow-space">FSN - The Revolution</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content>
        <ion-router-outlet id="main-content" />
      </ion-content>

      <ion-footer class="app-footer">
        <RadioPlayer />
      </ion-footer>
      <radio-stream-info ref="radioStreamInfo"></radio-stream-info>
    </ion-page>
    
  </ion-app>
</template>

<script>
import RadioPlayer from './components/RadioPlayer.vue'
import RadioStreamInfo from './components/RadioStreamInfo.vue'
import { 
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonMenu,
  IonMenuToggle,
  IonMenuButton,
  IonApp, 
  IonRouterOutlet,
  IonItem,
  IonList,
  IonFooter,
  alertController } from '@ionic/vue';

export default ({
  name: 'App',
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    IonApp,
    IonRouterOutlet,
    IonItem,
    IonList,
    RadioPlayer,
    IonFooter,
    RadioStreamInfo
  },
  data () {
    return {

    }
  },
  mounted() {
    console.log('app init done')
    this.getAppInfos()
  },
  methods: {
    getAppInfos() {
      var thisAppVersion = this.$store.state.appVersion
      this.axios.get(this.$store.state.xhrBaseUrl + '/appInfos.json').then((response) => {
        if(parseInt(thisAppVersion) < parseInt(response.data.version_number)) {
          console.log('neue version verfügbar');
          this.showUpdateAlert(response.data.apkFile)
        }
      })
    },
    async showUpdateAlert(apkUrl) {
      const alert = await alertController
        .create({
          cssClass: 'update-alert',
          header: 'FSN-APP Update',
          message: 'Eine neue Version ist verfügbar. Klicke auf "AKTUALISIEREN" um die neueste App-Version zu laden und zu installieren.',
          buttons: [
            {
              text: 'Abbrechen',
              role: 'cancel',
              cssClass: 'primary',
              handler: () => {
                console.log('Confirm Cancel')
              },
            },
            {
              text: 'Aktualisieren',
              handler: () => {
                window.open(apkUrl)
              },
            },
          ],
        });
      return alert.present();
    }
  }
});
</script>

<style scoped>

</style>