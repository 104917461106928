import { createStore } from 'vuex'

export default createStore({
  //https://app.radio-fsn.de/api
  //https://pannewasch.de/test-cors/api
  state: {
    xhrBaseUrl: 'https://pannewasch.de/test-cors/api',
    appVersion: '0004',
    streamUrl: 'https://stream.radio-fsn.de/rock',
    currentSong: 'Das Radio läuft gerade nicht',
    streamName: 'Rock'
  },
  mutations: {
    streamUrlChange(state, streamUrl) {
      state.streamUrl = streamUrl
    },
    currentSongChange(state, currentSong) {
      state.currentSong = currentSong
    },
    currentStreamNameChange(state, streamName) {
      state.streamName = streamName
    }
  },
  actions: {
  },
  modules: {
  }
})
