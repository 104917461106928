<template>
  <div class="player">
    <div class="play-pause" @click="playPause">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
        <path v-if="radioIsRunning" fill="#F4E518" fill-rule="evenodd" d="M0 0h6v24H0zM12 0h6v24h-6z" class="play-pause-icon" id="playPause"></path>
        <path v-else fill="#F4E518" fill-rule="evenodd" d="M18 12L0 24V0" class="play-pause-icon" id="playPause"></path>
      </svg>
    </div>
    <div class="volume">
      <div class="volume-range-wrapper">
        <div class="player-current-song">
          <span class="stream-title  yellow-space">FSN - {{ streamName }}</span><br>
          <span class="player-song-title">{{ currentSong }}</span>
        </div>
      </div>
      <div class="volume-icon" @click="muteUnmuteRadio">
        <svg v-if="radioIsMuted" class="muted" xmlns="http://www.w3.org/2000/svg" version="1.0" width="500" height="500" viewBox="0 0 75 75"
        stroke="#F4E518" stroke-width="5">
          <path d="m39,14-17,15H6V48H22l17,15z" fill="#F4E518" stroke-linejoin="round"/>
          <path d="m49,26 20,24m0-24-20,24" fill="none" stroke-linecap="round"/>
        </svg>
        <svg v-else class="not-muted" xmlns="http://www.w3.org/2000/svg" version="1.0"  width="500" height="500" viewBox="0 0 75 75">
          <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"
          style="stroke:#F4E518;stroke-width:5;stroke-linejoin:round;fill:#F4E518;"/>
          <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke:#F4E518;stroke-width:5;stroke-linecap:round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
//import RadioStreamInfo from './components/RadioStreamInfo.vue'
export default {
  data() {
    return {
      radioIsRunning: false,
      radioAudio: new Audio(this.$store.state.streamUrl),
      radioIsMuted: false
    }
  },

  computed: {
    streamUrl: {
      get() {
          return this.$store.state.streamUrl
      },
      set() {

      }
    },
    currentSong: {
      get() {
          return this.$store.state.currentSong
      },
      set() {

      }
    },
    streamName: {
      get() {
          return this.$store.state.streamName
      },
      set() {

      }
    }
  },
  watch: {
   streamUrl: function (new_value) {
      this.radioAudio.src = new_value;
      this.playPause()
   },
   currentSong: function (new_value) {
      this.currentSong = new_value;
   },
   streamName: function (new_value) {
      this.currentSong = new_value;
   }
  },
  mounted() {
  },
  methods: {
    playPause() {
      if (this.radioIsRunning) {
        this.radioAudio.pause()
        this.radioIsRunning = false
      } else {
        this.radioAudio.play()
        this.radioIsRunning = true
      }
    },
    changeVolume(e) {
      console.log(this.radioAudio);
      console.log(e.currentTarget.value)
      this.radioAudio.volume = e.currentTarget.value / 100;
      console.log(e.currentTarget.value / 100);

      this.radioAudio.volume === 0 ? this.radioIsMuted = true : this.radioIsMuted = false
    },
    muteUnmuteRadio() {
      if (this.radioIsMuted) {
        this.radioIsMuted = false
        this.radioAudio.volume = 50 / 100
      } else {
        this.radioIsMuted = true
        this.radioAudio.volume = 0
      }
    }
  }
}
</script>

<style lang="scss">
.player {
  display: flex;
  /*position: fixed;*/
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid #F4E518;;
  background-color: #000;

  .play-pause {
    border-right: 1px solid #F4E518;
    display: flex;
    width: 10%;
    min-width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .player-song-title {
    max-height: 20px;
    overflow: hidden;
    display: block;
  }

  .volume {
    width: 90%;
    height: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .volume-icon {
        cursor: pointer;
        width: 6%;
        min-width: 34px;
        margin-left: 2%;
        display: flex;
        justify-content: flex-end;

      svg {
        width: 34px;
        height: 34px;
        
      }
    }

    .volume-range-wrapper {
      width: 88%;

      input[type=range]{
        -webkit-appearance: none;
        cursor: pointer;
        width: 100%;
      }

      input[type=range]::-webkit-slider-runnable-track {
          width: 300px;
          height: 5px;
          background:#F4E518;
          border: none;
          border-radius: 3px;
      }

      input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background: #F4E518;
          margin-top: -4px;
      }

      input[type=range]:focus {
          outline: none;
      }

      input[type=range]:focus::-webkit-slider-runnable-track {
          background:#F4E518;
      }
    }
  }
}
</style>